@import 'src/styles/media';

.wrapper {
  background: url('/images/error-page-bg.png') center center no-repeat;
  background-size: cover;
  min-height: calc(100vh - var(--header-height));
  /* max-width: 1650px; */
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: var(--primary-text-color);
  height: 100vh;
  padding: 0 20px;
}
.title {
  font-size: 222px;
  margin: 0;
  line-height: 1;

  @include media-sm {
    font-size: 80px;
  }
}
.description {
  font-size: 21px;
  margin-top: 0;
  max-width: 535px;
  text-align: center;

  @include media-sm {
    font-size: 18px;
  }
}
.link {
  border-radius: 20px;
  background-color: #5f48e9;
  font-size: 15px;
  color: #fff;
  padding: 10px 60px;
}
